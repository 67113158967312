const handleControls = (swiper, controls) => {
  if (controls) {
    if (swiper.isBeginning && swiper.isEnd) {
      controls.classList.add('force-hidden')
    } else {
      controls.classList.remove('force-hidden')
    }
  }
}

const init = async () => {
  import('swiper/css')
  const { default: Swiper, Navigation, Pagination } = await import('swiper')

  Swiper.use([Navigation, Pagination])

  document.querySelectorAll('.element-newsevents').forEach((el) => {
    const container = el.querySelector('.swiper')
    const numSlides = el.querySelectorAll('.swiper-slide').length
    const controls = el.querySelector('.slider-controls')

    if (numSlides > 1) {
      new Swiper(container, {
        slidesPerView: 'auto',
        preloadImages: false,
        simulateTouch: false,
        loop: true,
        loopedSlides: numSlides,
        pagination: {
          el: el.querySelector('.slider-pagination'),
          type: 'custom',
          renderCustom: (_, current, total) => {
            return `${current} of ${total}`
          },
        },
        navigation: {
          nextEl: el.querySelector('.slider-control-next'),
          prevEl: el.querySelector('.slider-control-prev'),
        },
        breakpoints: {
          768: {
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerGroup: 3,
          },
        },
        on: {
          resize: (swiper) => handleControls(swiper, controls),
          afterInit: (swiper) => handleControls(swiper, controls),
        },
      })
    }
  })
}

init()
